import type { FieldErrors, FieldValues } from "react-hook-form";

const getFieldNameErrorMessage = (
  name: string,
  errors: FieldErrors<FieldValues>
): React.ReactNode | undefined => {
  const arrayName = name.split(".");

  if (arrayName.length > 1 && Object.keys(errors).length > 0) {
    let errorObj: Record<string, unknown> = errors;

    for (const n of arrayName) {
      if (Array.isArray(errorObj)) {
        errorObj = errorObj[parseInt(n)] as Record<string, unknown>;
        continue;
      }

      if (!errorObj || !Object.keys(errorObj).includes(n)) {
        break;
      }
      errorObj = errorObj[n] as Record<string, unknown>;
    }
    return errorObj?.message as React.ReactNode | undefined;
  } else if (Object.keys(errors).length > 0) {
    const message = (errors[name]?.message ?? errors[name]?.root?.message) as
      | React.ReactNode
      | undefined;

    const messages = errors[name]?.types ?? {};
    if (!message && Object.keys(messages).length > 0) {
      return Object.values(messages).map((t) => t) as React.ReactNode;
    }

    return message;
  }
  return undefined;
};

export default getFieldNameErrorMessage;
