import HttpClient from "../common/lib/httpClient";
import { ApiPagination, ApiQuery } from "../common/types/sharedTypes";

import type {
  ProjectDetails,
  ProjectsItem,
  ProjectsList,
} from "./Project.types";

const getOne = async (id: string): Promise<ProjectDetails> => {
  return HttpClient.get(`/projects/${id}`);
};

const getList = async (
  params: ApiPagination &
    ApiQuery & { customer?: string; type?: string; status?: string }
): Promise<ProjectsList> => {
  return HttpClient.get(`/projects`, params);
};

const getProjects = async (search: string): Promise<ProjectsItem[]> => {
  return HttpClient.get(`/projects/filters-search`, {
    q: search,
  });
};

export default {
  getOne,
  getList,
  getProjects,
};
