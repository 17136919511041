import { GutterMarker } from "@uiw/react-codemirror";

export class QuestionMarker extends GutterMarker {
  private text: string;

  constructor(text: string) {
    super();
    this.text = text;
  }
  toDOM() {
    const span = document.createElement("span");
    span.textContent = " " + this.text;
    return span;
  }
}
