import type { Question } from "./QuestionValidator";

type QuestionProperty = keyof Question;
type ValidationItem = Record<string, unknown>;

export class Validators {
  validateRequiredProperty(
    question: Question,
    property: QuestionProperty
  ): string | null {
    if (!question) {
      return `Question object is null or undefined.`;
    }

    const value = question[property];

    if (value == null || value.toString().trim() === "") {
      return `Property '${property}' is required and cannot be empty.`;
    }

    return null;
  }

  validateAllowedPropertiesByQuestionType(
    validProperties: string[],
    item: ValidationItem
  ): string[] {
    const ignoreFields = new Set(["line"]);
    const validPropertySet = new Set(validProperties);

    return Object.keys(item).reduce<string[]>((errors, key) => {
      if (!validPropertySet.has(key) && !ignoreFields.has(key)) {
        errors.push(`Property '${key}' not expected.`);
      }
      return errors;
    }, []);
  }

  validateEnum(
    question: Question,
    property: QuestionProperty,
    allowedValues: unknown[]
  ): string | null {
    const formatter = new Intl.ListFormat("en", {
      style: "long",
      type: "disjunction",
    });

    if (question[property] && !allowedValues.includes(question[property])) {
      return `Invalid value '${
        question[property]
      }' for '${property}' property\nexpected enum [${formatter.format(
        allowedValues as string[]
      )}]`;
    }
    return null;
  }

  validateBoolean(
    question: Question,
    property: QuestionProperty
  ): string | null {
    if (
      property in question &&
      !["true", "false"].includes(
        (question[property as keyof Question] ?? "").toString().toLowerCase()
      )
    ) {
      return `Invalid value '${
        question[property as keyof Question]
      }' for '${property}' property, expected boolean [true, false].`;
    }
    return null;
  }

  validateInteger(
    question: Question,
    property: QuestionProperty
  ): string | null {
    if (
      property in question &&
      isNaN(parseInt(question[property as keyof Question] as string))
    ) {
      return `Invalid value '${
        question[property as keyof Question]
      }' for '${property}' property, expected integer.`;
    }
    return null;
  }

  validateIntegerBetween(
    question: Question,
    property: QuestionProperty,
    minValue: number,
    maxValue: number
  ): string | null {
    if (
      question[property] != null &&
      ((question[property] as number) < minValue ||
        (question[property] as number) > maxValue)
    ) {
      return `Property '${property}' must be between ${minValue} and ${maxValue}.`;
    }
    return null;
  }

  validateIntegerMinMax(
    question: Question,
    minProperty: QuestionProperty,
    maxProperty: QuestionProperty,
    canBeEqual: boolean = true
  ): string | null {
    const min = (question[minProperty] as number) ?? undefined;
    const max = (question[maxProperty] as number) ?? undefined;

    if (!isNaN(min) && min < 0) {
      return `Property '${minProperty}' cannot be less than 0 (zero).`;
    }

    if (!isNaN(max) && max < 1) {
      return `Property '${maxProperty}' cannot be less than 1 (one).`;
    }

    if (min > max) {
      return `Property '${minProperty}' cannot be bigger than '${maxProperty}'.`;
    }

    if (!canBeEqual && min != null && max != null && min === max) {
      return `Property '${minProperty}' cannot be equal to '${maxProperty}'.`;
    }

    return null;
  }

  validateIntegerMaxValue(
    question: Question,
    property: QuestionProperty,
    maxValue: number
  ): string | null {
    const min = (question[property] as number) ?? undefined;

    if (!isNaN(min) && min > maxValue) {
      return `Property '${property}' cannot be bigger than ${maxValue}.`;
    }
    return null;
  }

  validateList(question: Question, property: QuestionProperty): string | null {
    if (
      question[property] != null &&
      ((question[property] as string[]) ?? []).length === 0
    ) {
      return `Property '${property}' requires at least one option.`;
    }
    return null;
  }

  validateListStaticSize(
    question: Question,
    property: QuestionProperty,
    fixedSize: number
  ): string | null {
    if (
      question[property] != null &&
      ((question[property] as string[]) ?? []).length !== fixedSize
    ) {
      const itemPluralize = fixedSize == 1 ? "item" : "items";
      return `Property '${property}' must have exactly ${fixedSize} ${itemPluralize}.`;
    }
    return null;
  }
}
