import { RangeSet, StateField } from "@uiw/react-codemirror";
import jsYaml from "js-yaml";

import { QuestionMarker } from "./QuestionMarker";
import type { Question } from "./QuestionValidator";
import QuestionValidator from "./QuestionValidator";

type Line = {
  from: number;
  to: number;
  value: QuestionMarker;
};

export function createQuestionNumberingField() {
  return StateField.define({
    create() {
      return RangeSet.empty;
    },
    update(_, tr) {
      const doc = tr.state.doc;
      const lines: Line[] = [];

      let dummyCounter = 1;
      let questionCounter = 1;

      try {
        const parsedYaml = jsYaml.load(doc.toString()) as Question[];

        let currentLine = 1;
        let typePos = 0;
        while (currentLine <= doc.lines) {
          const line = doc.line(currentLine);
          if (line.text.startsWith("- ")) {
            const q: Question = parsedYaml[typePos];
            if (q === null) {
              currentLine++;
              continue;
            }

            if ("type" in q && q["type"] === "dummy") {
              lines.push({
                from: line.from,
                to: line.from,
                value: new QuestionMarker(`D${dummyCounter}`),
              });
              dummyCounter++;
            } else if (
              "type" in q &&
              QuestionValidator.ALLOWED_TYPE_VALUES.includes(
                q["type"] as
                  | (typeof QuestionValidator.ALLOWED_TYPE_VALUES)[number]
              )
            ) {
              lines.push({
                from: line.from,
                to: line.from,
                value: new QuestionMarker(`Q${questionCounter}`),
              });
              questionCounter++;
            }
            typePos++;
          }
          currentLine++;
        }
      } catch (err) {
        return RangeSet.of(lines);
      }

      return RangeSet.of(lines);
    },
  });
}
