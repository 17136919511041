import { Diagnostic } from "@codemirror/lint";
import { EditorView } from "@uiw/react-codemirror";

import QuestionValidator from "./QuestionValidator";

export default class QreLinter {
  static validateYaml(
    view: EditorView
  ): readonly Diagnostic[] | Promise<readonly Diagnostic[]> {
    const doc = view.state.doc;
    const validator = new QuestionValidator();

    return validator.validate(doc);
  }
}
