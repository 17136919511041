import { Box, TextField, TextFieldProps } from "@mui/material";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";

import getFieldNameErrorMessage from "../../utils/fieldNameErrorMessage";

export type Props = {
  name: string;
  registerOptions?: RegisterOptions;
} & TextFieldProps;

export function FormTextField({
  name,
  size = "small",
  disabled,
  helperText,
  ...rest
}: Props) {
  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext();

  let errorMessage = getFieldNameErrorMessage(name, errors);
  if (Array.isArray(errorMessage)) {
    errorMessage = errorMessage.map((e) => <Box key={e}>{e}</Box>);
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <TextField
            error={!!errorMessage}
            helperText={errorMessage || helperText}
            disabled={disabled || isSubmitting}
            size={size}
            {...field}
            {...rest}
            slotProps={{
              htmlInput: {
                "data-testid": field.name,
              },
            }}
          />
        );
      }}
    />
  );
}
