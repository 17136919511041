import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import { Box, Stack, TextField } from "@mui/material";
import { dracula, vscodeLight } from "@uiw/codemirror-themes-all";
import {
  Controller,
  DeepPartial,
  SubmitHandler,
  useFormContext,
} from "react-hook-form";

import { QREDetails } from "../QRE.types";
import { QRESchema, QRESchemaType } from "../schemas";

import Form from "@/modules/common/components/form/Form";
import { FormTextField } from "@/modules/common/components/form/FormTextField";
import SubmitBar from "@/modules/common/components/form/SubmitBar";
import FormSection from "@/modules/common/components/layout/forms/FormSection";
import ProjectsAutocomplete from "@/modules/common/components/ProjectAutocomplete";
import QreEditor from "@/modules/common/components/yamleditor/QreEditor";
import { useThemeModeStore } from "@/theme";

type QREFormFields = {
  isReadOnly?: boolean;
};
const QREFormFields = ({ isReadOnly }: QREFormFields) => {
  const { mode } = useThemeModeStore();
  const { control } = useFormContext();

  return (
    <Stack spacing={4}>
      <FormSection
        icon={EditLocationAltIcon}
        title="QRE Info"
        description="Basic information about the QRE"
      >
        <Stack spacing={4}>
          <FormTextField name="name" label="Name" disabled={isReadOnly} />
          <FormTextField
            name="title"
            label="Mission Title"
            disabled={isReadOnly}
          />
          <Controller
            name="project"
            control={control}
            disabled={isReadOnly}
            render={({ field, formState }) => {
              return (
                <ProjectsAutocomplete
                  {...field}
                  sx={{
                    width: "100%",
                  }}
                  onChange={(e, value) => {
                    field.onChange(value);
                  }}
                  error={!!formState.errors.project}
                  helperText={formState.errors.project?.message as string}
                />
              );
            }}
          />
        </Stack>
      </FormSection>

      <FormSection
        icon={EditLocationAltIcon}
        title="QRE Form"
        description="Questions details"
        direction="column"
      >
        <Stack spacing={4}>
          <Box
            sx={{
              height: "70vh",
            }}
          >
            <Controller
              name="form"
              control={control}
              disabled={isReadOnly}
              render={({ field }) => {
                return (
                  <QreEditor
                    theme={mode === "dark" ? dracula : vscodeLight}
                    {...field}
                  />
                );
              }}
            />
          </Box>
        </Stack>
      </FormSection>

      <FormSection
        icon={EditLocationAltIcon}
        title="QRE Comments"
        description="Comments about the QRE"
      >
        <Stack spacing={2}>
          <Controller
            name="comments"
            control={control}
            disabled={isReadOnly}
            render={({ field }) => {
              return (
                <TextField
                  multiline
                  minRows={4}
                  maxRows={8}
                  label="Comments"
                  fullWidth
                  disabled={isReadOnly}
                  {...field}
                />
              );
            }}
          />
        </Stack>
      </FormSection>
      {!isReadOnly && <SubmitBar />}
    </Stack>
  );
};

type QREFormProps =
  | {
      onSubmit: SubmitHandler<QRESchemaType>;
      initialValues?: DeepPartial<QRESchemaType>;
      isReadOnly?: never;
    }
  | {
      onSubmit?: undefined;
      initialValues: DeepPartial<QREDetails & QRESchemaType>;
      isReadOnly: true;
    };
const QREForm = ({ initialValues, onSubmit, isReadOnly }: QREFormProps) => {
  return (
    <Form
      onSubmit={onSubmit ?? (() => null)}
      schema={QRESchema}
      defaultValues={{
        ...initialValues,
      }}
    >
      <QREFormFields isReadOnly={isReadOnly} />
    </Form>
  );
};

export default QREForm;
