import { Box } from "@mui/material";
import type { ReactCodeMirrorProps } from "@uiw/react-codemirror";
import ReactCodeMirror from "@uiw/react-codemirror";
import { forwardRef } from "react";

type YAMLEditorProps = ReactCodeMirrorProps;
const YAMLEditor = forwardRef(function YAMLEditor(props: YAMLEditorProps) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        ".cm-editor": { height: "100%" },
        ".cm-scroller": { overflow: "auto" },
        ".cm-theme": { height: "100%" },
        ".cm-question-gutter .cm-gutterElement": {
          paddingLeft: "20px",
          minWidth: "40px",
        },
      }}
    >
      <ReactCodeMirror {...props} />
    </Box>
  );
});

export default YAMLEditor;
