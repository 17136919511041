import { autocompletion, completionKeymap } from "@codemirror/autocomplete";
import { yaml } from "@codemirror/lang-yaml";
import { linter, lintGutter } from "@codemirror/lint";
import {
  Compartment,
  EditorState,
  Extension,
  keymap,
} from "@uiw/react-codemirror";

import QreCompleter from "./QreCompleter";
import QreGutter from "./QreGutter";
import QreLinter from "./QreLinter";

class QreEditorCore {
  static createExtensions(): Extension[] {
    const tabSize: Compartment = new Compartment();

    return [
      QreGutter.createGutter(),
      tabSize.of(EditorState.tabSize.of(2)),
      yaml(),
      linter(QreLinter.validateYaml),
      lintGutter(),
      autocompletion({
        override: [QreCompleter.createCompletions],
        activateOnTyping: true,
        closeOnBlur: false,
        maxRenderedOptions: 50,
      }),
      keymap.of(completionKeymap),
    ];
  }
}

export default QreEditorCore;
