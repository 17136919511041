import { CompletionContext, CompletionResult } from "@codemirror/autocomplete";

import QuestionCompleter from "./QuestionCompleter";

export default class QreCompleter {
  static createCompletions(
    context: CompletionContext
  ): CompletionResult | null {
    const completer = new QuestionCompleter();
    return completer.complete(context);
  }
}
