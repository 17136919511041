import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";

import QREForm from "../form/QREForm";
import type { QREDetails } from "../QRE.types";

const QREDetails = () => {
  const params = useParams();
  const id = params.id as string;
  const queryClient = useQueryClient();

  const qre = queryClient.getQueryData(["qre", { id }]) as QREDetails;

  return (
    <QREForm
      initialValues={{
        ...qre,
        project: { value: qre.project.id, label: qre.project.name },
      }}
      isReadOnly
    />
  );
};

export default QREDetails;
