import { Route, Routes } from "react-router";

import AuthorizedElement from "../auth/AuthorizedElement";
import { useAdm4Rollback } from "../auth/useAdm4Rollback";

import QREChanges from "./pages/QREChanges/QREChanges";
import QRECreate from "./pages/QRECreate";
import QREDetails from "./pages/QREDetails";
import QREEdit from "./pages/QREEdit";
import QREList from "./pages/QREList";
import { QREPage } from "./pages/QREPage/QREPage";
import QREVersion from "./pages/QREVersion/QREVersion";

import GoToAdm4 from "@/router/GoToAdm4";

const QRERoutes = () => {
  useAdm4Rollback("qre");
  return (
    <Routes>
      <Route
        index
        element={
          <AuthorizedElement requiredRoles="qre::list" element={<QREList />} />
        }
      />
      <Route
        path="new"
        element={
          <AuthorizedElement
            requiredRoles="qre::create"
            element={<QRECreate />}
          />
        }
      />
      <Route
        path=":id"
        element={
          <AuthorizedElement
            requiredRoles="qre::detail"
            element={<QREPage />}
          />
        }
      >
        <Route index element={<QREDetails />} />
        <Route
          path="changes"
          element={
            <AuthorizedElement
              requiredRoles="qre::detail"
              element={<QREChanges />}
            />
          }
        />
        <Route
          path="version"
          element={
            <AuthorizedElement
              requiredRoles="qre::detail"
              element={<QREVersion />}
            />
          }
        />
        <Route
          path="edit"
          element={
            <AuthorizedElement
              requiredRoles="qre::detail"
              element={<QREEdit />}
            />
          }
        />
      </Route>
      <Route path="*" element={<GoToAdm4 />} />
    </Routes>
  );
};

export default QRERoutes;
