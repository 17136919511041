import { CompletionContext, CompletionResult } from "@codemirror/autocomplete";
import jsYaml from "js-yaml";

import { Question } from "./QuestionValidator";

export default class QuestionCompleter {
  isInsideList(context: CompletionContext, listNames: string[]): boolean {
    const doc = context.state.doc;
    const currentLine = doc.lineAt(context.pos);
    const currentIndent = (currentLine.text.match(/^\s*/)?.[0] || "").length;

    // Walk backwards through lines
    let line = currentLine;
    while (line.number > 1) {
      line = doc.line(line.number - 1);
      const lineIndent = (line.text.match(/^\s*/)?.[0] || "").length;

      if (lineIndent < currentIndent) {
        const keyMatch = line.text.match(/^\s*([\w-]+):/);
        return keyMatch ? listNames.includes(keyMatch[1]) : false;
      }
    }
    return false;
  }
  complete(context: CompletionContext): CompletionResult | null {
    const word = context.matchBefore(/\w*/);

    let parsedYaml = null;
    const doc = context.state.doc;
    const line = doc.lineAt(context.pos);
    try {
      const textBeforeCursor = doc.sliceString(0, context.pos - 1);
      parsedYaml = jsYaml.load(textBeforeCursor) as Question[];
    } catch (err) {
      /* empty */
    }

    const beforeCursor = line.text.slice(0, context.pos - line.from);
    if (
      (beforeCursor.startsWith(" ") || beforeCursor.startsWith("- ")) &&
      beforeCursor.trim().indexOf(":") === -1 &&
      !this.isInsideList(context, ["rows", "cols", "options", "labels"])
    ) {
      const letterTyped = beforeCursor.replace("-", "").trim();
      let options = ["q", "type", "note"];

      const q = parsedYaml?.at(-1);
      switch (q?.type) {
        case "text": {
          options = [
            ...options,
            "required",
            "anchored",
            "minwords",
            "maxwords",
            "minchars",
            "maxchars",
            "multiline",
          ];
          break;
        }
        case "number": {
          options = [
            ...options,
            "required",
            "anchored",
            "min",
            "max",
            "subtype",
          ];
          break;
        }
        case "boolean": {
          options = [...options, "anchored"];
          break;
        }
        case "photo": {
          options = [...options, "required", "anchored", "gallery"];
          break;
        }
        case "audio": {
          options = [...options, "required", "anchored", "duration"];
          break;
        }
        case "video": {
          options = [...options, "required", "anchored", "gallery", "duration"];
          break;
        }
        case "select": {
          options = [
            ...options,
            "required",
            "anchored",
            "min",
            "max",
            "random",
            "exclusive",
            "other",
            "options",
          ];
          break;
        }
        case "sort": {
          options = [...options, "required", "anchored", "random", "options"];
          break;
        }
        case "rating": {
          options = [
            ...options,
            "required",
            "anchored",
            "min",
            "max",
            "labels",
          ];
          break;
        }
        case "matrix": {
          options = [
            ...options,
            "required",
            "anchored",
            "rows",
            "cols",
            "randomize",
            "cell-type",
          ];
          switch (q?.["cell-type"]) {
            case "select": {
              options = [...options, "options", "random"];
              break;
            }
            case "boolean": {
              options = [...options, "theme"];
              break;
            }
            case "rating": {
              options = [...options, "labels", "min", "max"];
              break;
            }
            case "number": {
              options = [...options, "number-type", "min", "max"];
              break;
            }
          }
          break;
        }
      }

      if (letterTyped) {
        options = options.filter((p) => p.startsWith(letterTyped));
      }

      if (!options) {
        return null;
      }

      return {
        from: word?.from ?? 0,
        options: options.map((p) => ({
          label: p,
          type: "property",
          apply: p + ": ",
        })),
      };
    }

    if (beforeCursor.trim().indexOf("subtype:") !== -1) {
      return {
        from: word?.from ?? 0,
        options: [
          { label: "integer", type: "enum" },
          { label: "float", type: "enum" },
          { label: "currency", type: "enum" },
        ],
      };
    }

    if (beforeCursor.trim().indexOf("cell-type:") !== -1) {
      return {
        from: word?.from ?? 0,
        options: [
          { label: "text", type: "enum" },
          { label: "number", type: "enum" },
          { label: "select", type: "enum" },
          { label: "boolean", type: "enum" },
          { label: "rating", type: "enum" },
        ],
      };
    }

    if (beforeCursor.trim().indexOf("number-type:") !== -1) {
      return {
        from: word?.from ?? 0,
        options: [
          { label: "integer", type: "enum" },
          { label: "float", type: "enum" },
        ],
      };
    }

    if (beforeCursor.trim().indexOf("type:") !== -1) {
      return {
        from: word?.from ?? 0,
        options: [
          { label: "dummy", type: "enum" },
          { label: "text", type: "enum" },
          { label: "number", type: "enum" },
          { label: "boolean", type: "enum" },
          { label: "photo", type: "enum" },
          { label: "audio", type: "enum" },
          { label: "video", type: "enum" },
          { label: "select", type: "enum" },
          { label: "sort", type: "enum" },
          { label: "rating", type: "enum" },
          { label: "matrix", type: "enum" },
        ],
      };
    }

    if (beforeCursor.trim().indexOf("randomize:") !== -1) {
      return {
        from: word?.from ?? 0,
        options: [
          { label: "none", type: "enum" },
          { label: "rows", type: "enum" },
          { label: "cols", type: "enum" },
          { label: "both", type: "enum" },
        ],
      };
    }

    if (beforeCursor.trim().indexOf("theme:") !== -1) {
      return {
        from: word?.from ?? 0,
        options: [
          { label: "regular", type: "enum" },
          { label: "radio", type: "enum" },
          { label: "checkbox", type: "enum" },
          { label: "toggle", type: "enum" },
        ],
      };
    }

    if (
      beforeCursor.trim().indexOf("required:") !== -1 ||
      beforeCursor.trim().indexOf("anchored:") !== -1 ||
      beforeCursor.trim().indexOf("multiline:") !== -1 ||
      beforeCursor.trim().indexOf("gallery:") !== -1 ||
      beforeCursor.trim().indexOf("random:") !== -1
    ) {
      return {
        from: word?.from ?? 0,
        options: [
          { label: "true", type: "enum", apply: "true", detail: "Boolean" },
          { label: "false", type: "enum", apply: "false", detail: "Boolean" },
        ],
      };
    }

    return null;
  }
}
