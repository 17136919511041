import { Chip, ChipProps } from "@mui/material";

import { QRE_STATUS_COLORS } from "../utils/qreColors";

type QREStatusChipProps = ChipProps & {
  status: keyof typeof QRE_STATUS_COLORS;
};
const QREStatusChip = ({ status, ...rest }: QREStatusChipProps) => {
  if (Object.keys(QRE_STATUS_COLORS).indexOf(status) === -1) {
    return <Chip {...rest} label="-" />;
  }

  return (
    <Chip
      {...rest}
      label={status}
      sx={{
        ...rest.sx,
        backgroundColor:
          QRE_STATUS_COLORS[status as keyof typeof QRE_STATUS_COLORS],
        color: (theme) =>
          theme.palette.getContrastText(
            QRE_STATUS_COLORS[status as keyof typeof QRE_STATUS_COLORS]
          ),
        textTransform: "capitalize",
        fontWeight: "bold",
      }}
    />
  );
};

export default QREStatusChip;
