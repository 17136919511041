import FeedIcon from "@mui/icons-material/Feed";
import { useMutation } from "@tanstack/react-query";
import { To, useLocation, useNavigate } from "react-router";

import QREForm from "../form/QREForm";
import QRE from "../QRE";
import { QRESchemaType } from "../schemas";

import { useAuth } from "@/modules/auth/AuthContext";
import BackButtonLink from "@/modules/common/components/BackButtonLink";
import TitledPage from "@/modules/common/components/layout/pages/TitledPage";

const QRECreate = () => {
  const { checkAuthorization } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { mutateAsync } = useMutation({
    mutationFn: QRE.createQRE,
  });

  const showDetail = checkAuthorization("qre::detail");

  const handleCreation = async (data: QRESchemaType) => {
    return mutateAsync({ ...data, project: data.project?.value ?? null }).then(
      (resp) => {
        if (showDetail) {
          navigate(`/qre/${resp.id}`);
        } else {
          navigate("/qre");
        }
      }
    );
  };

  return (
    <TitledPage
      icon={FeedIcon}
      title="Create QRE"
      actions={
        <BackButtonLink
          to={location.state?.fromListing ? (-1 as To) : "/qre"}
        />
      }
    >
      <QREForm
        onSubmit={handleCreation}
        initialValues={{
          name: "",
          title: "",
          project: null,
          form: "",
          comments: "",
        }}
      />
    </TitledPage>
  );
};

export default QRECreate;
