import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";

import QREForm from "../form/QREForm";
import QRE from "../QRE";
import type { QREDetails } from "../QRE.types";
import { QRESchemaType } from "../schemas";

const QREEdit = () => {
  const params = useParams();
  const id = params.id as string;
  const queryClient = useQueryClient();

  const qre = queryClient.getQueryData(["qre", { id }]) as QREDetails;

  const { mutateAsync } = useMutation({
    mutationFn: QRE.edit,
  });

  const handleEdition = async (data: QRESchemaType) =>
    mutateAsync({ id, ...data, project: data.project?.value ?? null }).then(
      () => {
        queryClient.refetchQueries({
          queryKey: ["qre", { id }],
        });
      }
    );

  return (
    <QREForm
      onSubmit={handleEdition}
      initialValues={{
        ...qre,
        project: { value: qre.project.id, label: qre.project.name },
      }}
    />
  );
};

export default QREEdit;
