import { Button, ButtonProps, CircularProgress } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

const DEFAULT_LABEL = "Save";
const DEFAULT_SUBMITTING_LABEL = (
  <>
    <span>Saving</span>
    <CircularProgress
      size={18}
      color="inherit"
      sx={{
        ml: 1,
      }}
    />
  </>
);

export function FormSubmitButton({
  disabled,
  submittingLabel,
  children,
  ...props
}: ButtonProps & {
  submittingLabel?: React.ReactNode;
  children?: React.ReactNode;
}) {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  const label = children ?? DEFAULT_LABEL;
  const submitting = submittingLabel ?? children ?? DEFAULT_SUBMITTING_LABEL;

  return (
    <Button
      variant="contained"
      type="submit"
      disabled={disabled || isSubmitting}
      {...props}
    >
      {isSubmitting ? submitting : label}
    </Button>
  );
}
