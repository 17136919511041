import { Stack, Typography } from "@mui/material";

import Link from "./Link";

import { IMPLEMENTED_MODULES } from "@/modules/auth/Auth";
import { useAuth } from "@/modules/auth/AuthContext";

type EntityFormatterProps = {
  id: string;
  link?: string;
  label?: string;
  extras?: string[];
  labelLink?: boolean;
};
export const EntityFormatter = ({
  id,
  link,
  label,
  extras,
  labelLink,
}: EntityFormatterProps) => {
  if (labelLink) {
    return link ? (
      <Link to={link}>
        <Typography variant="button">{label}</Typography>
      </Link>
    ) : (
      <Typography variant="button">{label}</Typography>
    );
  }

  return (
    <Stack>
      {link ? (
        <Link to={link}>
          <Typography variant="body2">{id}</Typography>
        </Link>
      ) : (
        <Typography variant="body2">{id}</Typography>
      )}
      <Typography variant="button">{label}</Typography>
      {(extras ?? [])?.map((e) => (
        <Typography key={e} variant="caption">
          {e}
        </Typography>
      ))}
    </Stack>
  );
};

type RoleBasedEntityFormatter = {
  id: string;
  module: IMPLEMENTED_MODULES;
  label?: string;
  extras?: string[];
  labelLink?: boolean;
};
const RoleBasedEntityFormatter = ({
  id,
  module,
  label,
  extras,
  labelLink,
}: RoleBasedEntityFormatter) => {
  const { checkAuthorization } = useAuth();

  switch (module) {
    case "projects": {
      const canAccessProjects = checkAuthorization("projects::detail");
      const link = canAccessProjects ? `/projects/${id}` : undefined;
      return (
        <EntityFormatter
          id={id}
          link={link}
          label={label}
          extras={extras}
          labelLink={labelLink}
        />
      );
    }
    case "customers": {
      const canAccessCustomers = checkAuthorization("customers::detail");
      const link = canAccessCustomers ? `/customers/${id}` : undefined;
      return (
        <EntityFormatter
          id={id}
          link={link}
          label={label}
          extras={extras}
          labelLink={labelLink}
        />
      );
    }
    case "ausers": {
      const canAccessAusers = checkAuthorization("ausers::detail");
      const link = canAccessAusers ? `/ausers/${id}` : undefined;
      return (
        <EntityFormatter
          id={id}
          link={link}
          label={label}
          extras={extras}
          labelLink={labelLink}
        />
      );
    }
    default: {
      return (
        <EntityFormatter
          id={id}
          label={label}
          extras={extras}
          labelLink={labelLink}
        />
      );
    }
  }
};
export default RoleBasedEntityFormatter;
