import { Paper, Grid2, Stack, Typography, GridBaseProps } from "@mui/material";

type FormSectionProps = {
  children: React.ReactNode;
  icon?: React.ElementType;
  title: string;
  description: string;
} & Pick<GridBaseProps, "direction">;
const FormSection = ({
  children,
  title,
  icon: Icon,
  description,
  direction = "row",
}: FormSectionProps) => (
  <Paper sx={{ padding: { xs: 2, md: 6 } }}>
    <Grid2 container direction={direction} spacing={4}>
      <Grid2 size={{ xs: 12, sm: direction === "row" ? 5 : 12 }}>
        <Stack>
          <Stack direction="row">
            <Typography
              variant="h5"
              component="h5"
              sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}
            >
              {Icon && <Icon size={24} />}

              {title}
            </Typography>
          </Stack>
          <Typography variant="caption">{description}</Typography>
        </Stack>
      </Grid2>
      <Grid2 size={{ xs: 12, sm: direction === "row" ? 7 : 12 }}>
        {children}
      </Grid2>
    </Grid2>
  </Paper>
);

export default FormSection;
