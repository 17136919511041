import { Extension } from "@codemirror/state";
import { ReactCodeMirrorProps } from "@uiw/react-codemirror";
import { forwardRef } from "react";

import QreEditorCore from "./qre/QreEditorCore";
import YAMLEditor from "./YAMLEditor";

type QreEditorProps = {
  width?: string;
  height?: string;
  theme?: "none" | Extension | "light" | "dark";
  disabled?: boolean;
} & Pick<ReactCodeMirrorProps, "value" | "onChange">;
const QreEditor = forwardRef(function QreEditor(
  { disabled, ...props }: QreEditorProps,
  ref
) {
  const extensions = QreEditorCore.createExtensions();

  return (
    <YAMLEditor
      ref={ref}
      {...props}
      extensions={extensions}
      editable={!disabled}
    />
  );
});

export default QreEditor;
