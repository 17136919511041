import {
  Extension,
  gutter,
  RangeSet,
  RangeValue,
  StateField,
} from "@uiw/react-codemirror";

import { QuestionMarker } from "./QuestionMarker";
import { createQuestionNumberingField } from "./questionNumberingField";

class QreGutter {
  static createGutter(): (StateField<RangeSet<RangeValue>> | Extension)[] {
    const questionNumbering = createQuestionNumberingField();

    return [
      questionNumbering,
      gutter({
        class: "cm-question-gutter",
        markers: (view) => view.state.field(questionNumbering),
        initialSpacer: () => new QuestionMarker(""),
      }),
    ];
  }
}
export default QreGutter;
