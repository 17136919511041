import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router";

import GoToAdm4 from "./GoToAdm4";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";

import { useAuth } from "@/modules/auth/AuthContext";
import QueryErrorBoundary from "@/modules/common/components/error/QueryErrorBoundary";
import AppLayout, {
  AppLayoutSkeleton,
} from "@/modules/common/components/layout/AppLayout";
import QRERoutes from "@/modules/qres/routes";

const AnswersRoutes = lazy(() => import("@/modules/answers/routes"));
const AuserRoutes = lazy(() => import("@/modules/auser/routes"));
const BeaconsRoutes = lazy(() => import("@/modules/beacons/routes"));
const CustomerRoutes = lazy(() => import("@/modules/customer/routes"));
const DashboardRoutes = lazy(() => import("@/modules/dashboard/routes"));
const FaqRoutes = lazy(() => import("@/modules/faq/routes"));
const FlexViewRoutes = lazy(() => import("@/modules/flexviews/routes"));
const LiveRoutes = lazy(() => import("@/modules/live/routes"));
const LocationRoutes = lazy(() => import("@/modules/location/routes"));
const Login = lazy(() => import("@/modules/auth/pages/Login"));
const MergeRoutes = lazy(() => import("@/modules/merge/routes"));
const Profile = lazy(() => import("@/modules/auth/pages/Profile/Profile"));
const SPUberRoutes = lazy(() => import("@/modules/spuber/routes"));
const SupportRoutes = lazy(() => import("@/modules/support/routes"));
const UserRoutes = lazy(() => import("@/modules/users/routes"));
const ProjectRoutes = lazy(() => import("@/modules/projects/routes"));

const Home = () => {
  const { user } = useAuth();
  return <Navigate to={user?.home ?? "/dashboard"} />;
};

const AppRouter = () => {
  return (
    <Suspense fallback={<AppLayoutSkeleton />}>
      <Routes>
        <Route
          element={
            <QueryErrorBoundary>
              <PublicRoutes />
            </QueryErrorBoundary>
          }
        >
          <Route
            path="/login"
            element={
              <QueryErrorBoundary>
                <Login />
              </QueryErrorBoundary>
            }
          />
        </Route>
        <Route
          element={
            <QueryErrorBoundary>
              <ProtectedRoutes />
            </QueryErrorBoundary>
          }
        >
          <Route
            path="/"
            element={
              <QueryErrorBoundary>
                <AppLayout />
              </QueryErrorBoundary>
            }
          >
            <Route
              index
              element={
                <QueryErrorBoundary>
                  <Home />
                </QueryErrorBoundary>
              }
            />
            <Route
              path="/dashboard/*"
              element={
                <QueryErrorBoundary>
                  <DashboardRoutes />
                </QueryErrorBoundary>
              }
            />
            <Route
              path="/customers/*"
              element={
                <QueryErrorBoundary>
                  <CustomerRoutes />
                </QueryErrorBoundary>
              }
            />
            <Route
              path="/answers/approval/*"
              element={
                <QueryErrorBoundary>
                  <AnswersRoutes />
                </QueryErrorBoundary>
              }
            />
            <Route
              path="/merges/*"
              element={
                <QueryErrorBoundary>
                  <MergeRoutes />
                </QueryErrorBoundary>
              }
            />
            <Route
              path="/beacons/*"
              element={
                <QueryErrorBoundary>
                  <BeaconsRoutes />
                </QueryErrorBoundary>
              }
            />
            <Route
              path="/ausers/*"
              element={
                <QueryErrorBoundary>
                  <AuserRoutes />
                </QueryErrorBoundary>
              }
            />
            <Route
              path="/live/*"
              element={
                <QueryErrorBoundary>
                  <LiveRoutes />
                </QueryErrorBoundary>
              }
            />
            <Route
              path="/locations/*"
              element={
                <QueryErrorBoundary>
                  <LocationRoutes />
                </QueryErrorBoundary>
              }
            />
            <Route
              path="/spuber/*"
              element={
                <QueryErrorBoundary>
                  <SPUberRoutes />
                </QueryErrorBoundary>
              }
            />
            <Route
              path="/support/*"
              element={
                <QueryErrorBoundary>
                  <SupportRoutes />
                </QueryErrorBoundary>
              }
            />
            <Route
              path="/faq/*"
              element={
                <QueryErrorBoundary>
                  <FaqRoutes />
                </QueryErrorBoundary>
              }
            />
            <Route path="/analytics/flex/*" element={<FlexViewRoutes />} />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/users/"
              element={
                <QueryErrorBoundary>
                  <UserRoutes />
                </QueryErrorBoundary>
              }
            />
            <Route
              path="/projects/"
              element={
                <QueryErrorBoundary>
                  <ProjectRoutes />
                </QueryErrorBoundary>
              }
            />
            <Route
              path="/qre/*"
              element={
                <QueryErrorBoundary>
                  <QRERoutes />
                </QueryErrorBoundary>
              }
            />
            <Route path="*" element={<GoToAdm4 />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
