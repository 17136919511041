import { Diagnostic } from "@codemirror/lint";
import { Line, Text } from "@uiw/react-codemirror";
import jsYaml from "js-yaml";

import { Validators } from "./Validators";

type Severity = Diagnostic["severity"];

type ValidationFunction = (question: Question) => void;

export type Question = {
  q: string;
  type: string;
  line: Line;
  [key: string]: string | boolean | number | unknown[] | Line;
};

export default class QuestionValidator {
  public static readonly ALLOWED_TYPE_VALUES = [
    "dummy",
    "text",
    "number",
    "boolean",
    "photo",
    "audio",
    "video",
    "select",
    "sort",
    "rating",
    "matrix",
  ] as const;

  private static readonly REQUIRED_PROPS = ["q", "type"];
  public static readonly QUESTION_START = "- ";

  private diagnostics: Diagnostic[] = [];
  private validators: Validators = new Validators();

  private typeValidators: Record<string, ValidationFunction> = {
    dummy: this.validateDummy,
    text: this.validateText,
    number: this.validateNumber,
    boolean: this.validateBoolean,
    photo: this.validatePhoto,
    audio: this.validateAudio,
    video: this.validateVideo,
    select: this.validateSelect,
    sort: this.validateSort,
    rating: this.validateRating,
    matrix: this.validateMatrix,
  };

  validate(doc: Text): Diagnostic[] {
    this.diagnostics = [];
    let parsedYaml = null;
    try {
      parsedYaml = jsYaml.load(doc.toString()) as Question[];
    } catch (err) {
      this.addDiagnostic(0, doc.length, "error", (err as Error).message);
    }

    if (parsedYaml == null) {
      return this.diagnostics;
    }

    if (!Array.isArray(parsedYaml)) {
      this.addDiagnostic(0, doc.length, "error", "Malformed document");
      return this.diagnostics;
    }

    let currentLine = 1;
    let typePos = 0;
    while (currentLine <= doc.lines) {
      const line = doc.line(currentLine);
      if (line.text.startsWith(QuestionValidator.QUESTION_START)) {
        if (
          !(
            parsedYaml[typePos] instanceof Object &&
            parsedYaml[typePos].constructor === Object
          )
        ) {
          parsedYaml[typePos] = {} as Question;
        }
        parsedYaml[typePos]["line"] = line;
        typePos++;
      }
      currentLine++;
    }

    ((parsedYaml as Question[]) ?? []).forEach((question) => {
      if (question == null) {
        this.addDiagnostic(0, doc.length, "error", "Malformed element");
        return this.diagnostics;
      }

      this.validateQuestion(question);
    });

    return this.diagnostics;
  }

  private validateQuestion(question: Question): void {
    QuestionValidator.REQUIRED_PROPS.forEach((prop) => {
      const error = this.validators.validateRequiredProperty(question, prop);
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    const error = this.validators.validateEnum(
      question,
      "type",
      Array.from(QuestionValidator.ALLOWED_TYPE_VALUES)
    );
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }

    if ("type" in question) {
      const qType = question["type"];
      if (qType in this.typeValidators) {
        this.typeValidators[qType].call(this, question);
      }
    }
  }

  private validateDummy(question: Question): void {
    const ALLOWED_PROPERTIES = ["type", "q", "note"];
    const errors = this.validators.validateAllowedPropertiesByQuestionType(
      ALLOWED_PROPERTIES,
      question
    );
    errors?.forEach((error) => {
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });
  }

  private validateText(question: Question): void {
    const ALLOWED_PROPERTIES = [
      "type",
      "q",
      "required",
      "anchored",
      "minwords",
      "maxwords",
      "minchars",
      "maxchars",
      "multiline",
      "note",
    ];
    const errors = this.validators.validateAllowedPropertiesByQuestionType(
      ALLOWED_PROPERTIES,
      question
    );
    errors?.forEach((error) => {
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    ["required", "anchored", "multiline"].forEach((prop) => {
      const error = this.validators.validateBoolean(question, prop);
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    if (
      ("minwords" in question || "maxwords" in question) &&
      ("minchars" in question || "maxchars" in question)
    ) {
      this.addDiagnostic(
        question.line.from,
        question.line.to,
        "error",
        "It is not supported 'word' and 'char' limit at the same time."
      );
    }

    let error = this.validators.validateInteger(question, "minwords");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
    error = this.validators.validateInteger(question, "maxwords");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
    error = this.validators.validateInteger(question, "minchars");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
    error = this.validators.validateInteger(question, "maxchars");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }

    error = this.validators.validateIntegerMinMax(
      question,
      "minwords",
      "maxwords"
    );
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }

    error = this.validators.validateIntegerMinMax(
      question,
      "minchars",
      "maxchars"
    );
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
  }

  private validateNumber(question: Question): void {
    const ALLOWED_PROPERTIES = [
      "type",
      "q",
      "required",
      "anchored",
      "min",
      "max",
      "subtype",
      "note",
    ];
    const ALLOWED_SUBTYPES = ["integer", "float", "currency"];
    const errors = this.validators.validateAllowedPropertiesByQuestionType(
      ALLOWED_PROPERTIES,
      question
    );
    errors?.forEach((error) => {
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    let error = this.validators.validateRequiredProperty(question, "subtype");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
    error = this.validators.validateEnum(question, "subtype", ALLOWED_SUBTYPES);
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }

    ["required", "anchored"].forEach((prop) => {
      const error = this.validators.validateBoolean(question, prop);
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    error = this.validators.validateIntegerMinMax(
      question,
      "min",
      "max",
      false
    );
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
    error = this.validators.validateInteger(question, "min");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
    error = this.validators.validateInteger(question, "max");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
  }

  private validateBoolean(question: Question): void {
    const ALLOWED_PROPERTIES = ["type", "q", "anchored", "note"];
    const errors = this.validators.validateAllowedPropertiesByQuestionType(
      ALLOWED_PROPERTIES,
      question
    );
    errors?.forEach((error) => {
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    const error = this.validators.validateBoolean(question, "anchored");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
  }

  private validatePhoto(question: Question): void {
    const ALLOWED_PROPERTIES = [
      "type",
      "q",
      "required",
      "anchored",
      "gallery",
      "note",
    ];
    const errors = this.validators.validateAllowedPropertiesByQuestionType(
      ALLOWED_PROPERTIES,
      question
    );
    errors?.forEach((error) => {
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    ["required", "anchored", "gallery"].forEach((prop) => {
      const error = this.validators.validateBoolean(question, prop);
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });
  }

  private validateAudio(question: Question): void {
    const ALLOWED_PROPERTIES = [
      "type",
      "q",
      "required",
      "anchored",
      "duration",
      "note",
    ];
    const errors = this.validators.validateAllowedPropertiesByQuestionType(
      ALLOWED_PROPERTIES,
      question
    );
    errors?.forEach((error) => {
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    ["required", "anchored"].forEach((prop) => {
      const error = this.validators.validateBoolean(question, prop);
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    let error = this.validators.validateIntegerBetween(
      question,
      "duration",
      5,
      180
    );
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
    error = this.validators.validateInteger(question, "duration");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
  }

  private validateVideo(question: Question): void {
    const ALLOWED_PROPERTIES = [
      "type",
      "q",
      "required",
      "anchored",
      "gallery",
      "duration",
      "note",
    ];
    const errors = this.validators.validateAllowedPropertiesByQuestionType(
      ALLOWED_PROPERTIES,
      question
    );
    errors?.forEach((error) => {
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    ["required", "anchored", "gallery"].forEach((prop) => {
      const error = this.validators.validateBoolean(question, prop);
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    let error = this.validators.validateIntegerBetween(
      question,
      "duration",
      5,
      180
    );
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
    error = this.validators.validateInteger(question, "duration");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
  }

  private validateSelect(question: Question): void {
    const ALLOWED_PROPERTIES = [
      "type",
      "q",
      "required",
      "anchored",
      "min",
      "max",
      "random",
      "exclusive",
      "other",
      "options",
      "note",
    ];
    const errors = this.validators.validateAllowedPropertiesByQuestionType(
      ALLOWED_PROPERTIES,
      question
    );
    errors?.forEach((error) => {
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    ["required", "anchored", "random"].forEach((prop) => {
      const error = this.validators.validateBoolean(question, prop);
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    let error = this.validators.validateIntegerMinMax(question, "min", "max");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
    error = this.validators.validateInteger(question, "min");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
    error = this.validators.validateInteger(question, "max");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }

    error = this.validators.validateRequiredProperty(question, "options");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
  }

  private validateSort(question: Question): void {
    const ALLOWED_PROPERTIES = [
      "type",
      "q",
      "required",
      "anchored",
      "random",
      "options",
      "note",
    ];
    const errors = this.validators.validateAllowedPropertiesByQuestionType(
      ALLOWED_PROPERTIES,
      question
    );
    errors?.forEach((error) => {
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    ["required", "anchored", "random"].forEach((prop) => {
      const error = this.validators.validateBoolean(question, prop);
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    const error = this.validators.validateRequiredProperty(question, "options");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
  }

  private validateRating(question: Question): void {
    const ALLOWED_PROPERTIES = [
      "type",
      "q",
      "required",
      "anchored",
      "min",
      "max",
      "labels",
      "note",
    ];
    const errors = this.validators.validateAllowedPropertiesByQuestionType(
      ALLOWED_PROPERTIES,
      question
    );
    errors?.forEach((error) => {
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    ["required", "anchored"].forEach((prop) => {
      const error = this.validators.validateBoolean(question, prop);
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    let error = this.validators.validateIntegerMinMax(
      question,
      "min",
      "max",
      false
    );
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
    error = this.validators.validateIntegerMaxValue(question, "min", 1);
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
    error = this.validators.validateInteger(question, "min");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
    error = this.validators.validateInteger(question, "max");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }

    error = this.validators.validateListStaticSize(question, "labels", 3);
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
  }

  private validateMatrix(question: Question): void {
    const ALLOWED_PROPERTIES = [
      "type",
      "q",
      "required",
      "anchored",
      "rows",
      "cols",
      "randomize",
      "cell-type",
      "note",
    ];
    const ALLOWED_RANDOMIZE = ["none", "rows", "cols", "both"];
    const ALLOWED_CELLTYPES = ["text", "number", "select", "boolean", "rating"];
    const BOOLEAN_THEMES = ["regular", "radio", "checkbox", "toggle"];
    const NUMBER_TYPES = ["integer", "float"];

    let cellTypeProperties = ALLOWED_PROPERTIES;
    if (
      "cell-type" in question &&
      ALLOWED_CELLTYPES.includes(question["cell-type"] as string)
    ) {
      switch (question["cell-type"]) {
        case "select": {
          cellTypeProperties = [...cellTypeProperties, "options", "random"];

          let error = this.validators.validateBoolean(question, "random");
          if (error) {
            this.addDiagnostic(
              question.line.from,
              question.line.to,
              "error",
              error
            );
          }

          error = this.validators.validateRequiredProperty(question, "options");
          if (error) {
            this.addDiagnostic(
              question.line.from,
              question.line.to,
              "error",
              error
            );
          }
          break;
        }
        case "boolean": {
          cellTypeProperties = [...cellTypeProperties, "theme"];

          let error = this.validators.validateRequiredProperty(
            question,
            "theme"
          );
          if (error) {
            this.addDiagnostic(
              question.line.from,
              question.line.to,
              "error",
              error
            );
          }
          error = this.validators.validateEnum(
            question,
            "theme",
            BOOLEAN_THEMES
          );
          if (error) {
            this.addDiagnostic(
              question.line.from,
              question.line.to,
              "error",
              error
            );
          }

          break;
        }
        case "rating": {
          cellTypeProperties = [...cellTypeProperties, "labels", "min", "max"];

          let error = this.validators.validateIntegerMinMax(
            question,
            "min",
            "max",
            false
          );
          if (error) {
            this.addDiagnostic(
              question.line.from,
              question.line.to,
              "error",
              error
            );
          }
          error = this.validators.validateIntegerMaxValue(question, "min", 1);
          if (error) {
            this.addDiagnostic(
              question.line.from,
              question.line.to,
              "error",
              error
            );
          }
          error = this.validators.validateInteger(question, "min");
          if (error) {
            this.addDiagnostic(
              question.line.from,
              question.line.to,
              "error",
              error
            );
          }
          error = this.validators.validateInteger(question, "max");
          if (error) {
            this.addDiagnostic(
              question.line.from,
              question.line.to,
              "error",
              error
            );
          }

          error = this.validators.validateListStaticSize(question, "labels", 3);
          if (error) {
            this.addDiagnostic(
              question.line.from,
              question.line.to,
              "error",
              error
            );
          }
          break;
        }
        case "number": {
          cellTypeProperties = [
            ...cellTypeProperties,
            "number-type",
            "min",
            "max",
          ];

          let error = this.validators.validateRequiredProperty(
            question,
            "number-type"
          );
          if (error) {
            this.addDiagnostic(
              question.line.from,
              question.line.to,
              "error",
              error
            );
          }
          error = this.validators.validateEnum(
            question,
            "number-type",
            NUMBER_TYPES
          );
          if (error) {
            this.addDiagnostic(
              question.line.from,
              question.line.to,
              "error",
              error
            );
          }

          error = this.validators.validateIntegerMinMax(
            question,
            "min",
            "max",
            false
          );
          if (error) {
            this.addDiagnostic(
              question.line.from,
              question.line.to,
              "error",
              error
            );
          }
          error = this.validators.validateInteger(question, "min");
          if (error) {
            this.addDiagnostic(
              question.line.from,
              question.line.to,
              "error",
              error
            );
          }
          error = this.validators.validateInteger(question, "max");
          if (error) {
            this.addDiagnostic(
              question.line.from,
              question.line.to,
              "error",
              error
            );
          }
          break;
        }
      }
    }

    const errors = this.validators.validateAllowedPropertiesByQuestionType(
      cellTypeProperties,
      question
    );
    errors?.forEach((error) => {
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    ["required", "anchored"].forEach((prop) => {
      const error = this.validators.validateBoolean(question, prop);
      if (error) {
        this.addDiagnostic(
          question.line.from,
          question.line.to,
          "error",
          error
        );
      }
    });

    let error = this.validators.validateRequiredProperty(question, "rows");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
    error = this.validators.validateRequiredProperty(question, "cols");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }

    error = this.validators.validateEnum(
      question,
      "randomize",
      ALLOWED_RANDOMIZE
    );
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }

    error = this.validators.validateRequiredProperty(question, "cell-type");
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
    error = this.validators.validateEnum(
      question,
      "cell-type",
      ALLOWED_CELLTYPES
    );
    if (error) {
      this.addDiagnostic(question.line.from, question.line.to, "error", error);
    }
  }

  private addDiagnostic(
    from: number,
    to: number,
    severity: Severity,
    message: string
  ) {
    this.diagnostics.push({
      from,
      to,
      severity,
      message,
    });
  }
}
