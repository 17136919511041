import { z } from "zod";

export const QRESchema = z.object({
  name: z.string().trim().nonempty({ message: "Required" }),
  title: z.string().trim().nonempty({ message: "Required" }),
  project: z
    .object({
      value: z.string(),
      label: z.string(),
    })
    .nullable(),
  form: z.string(),
  comments: z.string(),
});
export type QRESchemaType = z.infer<typeof QRESchema>;
