import FeedIcon from "@mui/icons-material/Feed";
import {
  Button,
  Stack,
  TableCell,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router";

import QRE from "../QRE";
import { QREItem } from "../QRE.types";

import AUser from "@/modules/auser/AUser";
import { useAuth } from "@/modules/auth/AuthContext";
import AUsersSelectField from "@/modules/common/components/AUserAutocomplete";
import { CustomerOptions } from "@/modules/common/components/CustomerAutocomplete";
import RoleBasedEntityFormatter from "@/modules/common/components/EntityFormatter";
import { TableParams } from "@/modules/common/components/table/Table";
import { TableHeaderCellProps } from "@/modules/common/components/table/TableHeaderCell";
import TablePage from "@/modules/common/components/table/TablePage";
import { useTableDefaultRows } from "@/modules/common/components/table/useTableDefaultRows";
import useQueryParams from "@/modules/common/hooks/useQueryParams";
import formatDatetime from "@/modules/common/utils/formatDatetime";

type DefaultParams = TableParams & {
  createdBy?: string;
  status?: string;
};

const defaultParams: DefaultParams = {
  search: "",
  page: 1,
  perPage: useTableDefaultRows.getState().defaultRows,
  createdBy: "",
  status: "draft",
};

const headers: TableHeaderCellProps[] = [
  { label: "Name" },
  { label: "Mission Title" },
  { label: "Project" },
  { label: "Creator" },
  { label: "Updated At" },
  { label: "Status" },
  { label: "Actions", textAlign: "center" },
];

const QRERow = ({
  id,
  name,
  title,
  project,
  creator,
  updated,
  status,
  showDetail,
}: QREItem & { showDetail: boolean }) => (
  <TableRow key={id}>
    <TableCell>{name}</TableCell>
    <TableCell>{title}</TableCell>
    <TableCell>
      {project ? (
        <RoleBasedEntityFormatter
          module="projects"
          id={project.id}
          label={project.name}
        />
      ) : (
        "-"
      )}
    </TableCell>
    <TableCell>
      <RoleBasedEntityFormatter
        module="ausers"
        id={creator.id}
        label={creator.name}
      />
    </TableCell>
    <TableCell>{formatDatetime(new Date(updated))}</TableCell>
    <TableCell>{status}</TableCell>
    <TableCell align="center">
      {showDetail && (
        <Button variant="contained" component={Link} to={`/qre/${id}`}>
          Open
        </Button>
      )}
    </TableCell>
  </TableRow>
);

const STATUS_LIST = ["draft", "review", "amend", "approved"];
type FilterForm = {
  created_by: CustomerOptions | null;
  status?: string;
};
type QREFilterFormProps = {
  initialValues?: FilterForm;
  onChange: SubmitHandler<FilterForm>;
};
const QREFilterForm = ({ initialValues, onChange }: QREFilterFormProps) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      created_by: null,
      status: "",
    },
    values: {
      created_by: initialValues?.created_by ?? null,
      status: initialValues?.status ?? "",
    },
  });

  const handleSend = handleSubmit(onChange);

  return (
    <Stack
      gap={2}
      mb={4}
      direction="row"
      alignItems="flex-end"
      justifyContent="flex-end"
      flexWrap="wrap-reverse"
    >
      <Controller
        name="status"
        control={control}
        render={({ field }) => {
          return (
            <ToggleButtonGroup
              size="small"
              color="primary"
              exclusive
              {...field}
              onChange={(e, value) => {
                field.onChange(value);
                handleSend();
              }}
            >
              {STATUS_LIST.map((s) => {
                return (
                  <ToggleButton key={s} value={s} size="small">
                    {s}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          );
        }}
      />

      <Controller
        name="created_by"
        control={control}
        render={({ field }) => {
          return (
            <AUsersSelectField
              label="Created By"
              placeholder="Select the creator"
              {...field}
              onChange={(e, value) => {
                field.onChange(value);
                handleSend();
              }}
            />
          );
        }}
      />
    </Stack>
  );
};

const QREList = () => {
  const { checkAuthorization } = useAuth();
  const [showCreate, showDetail] = checkAuthorization([
    "qre::create",
    "qre::detail",
  ]);
  const [params, setParams] = useQueryParams(defaultParams);
  const page = parseInt(params.page);
  const perPage = parseInt(params.perPage);
  const { search, createdBy, status } = params;

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["qres", { search, createdBy, status, page, perPage }],

    queryFn: () =>
      QRE.getList({
        q: search,
        page,
        size: perPage,
        creator: createdBy,
        status,
      }),
  });

  const { data: dataAUser } = useQuery({
    queryKey: ["auser", createdBy],
    queryFn: () => AUser.getAUsers(createdBy),
  });

  const handleFilterChange = (data: FilterForm) => {
    setParams({
      createdBy: data.created_by?.value ?? "",
      status: data.status ?? "",
    });
  };

  return (
    <TablePage
      pageTitleIcon={FeedIcon}
      pageTitle="QREs"
      headers={headers}
      renderRow={(item) => (
        <QRERow {...item} key={item.id} showDetail={showDetail} />
      )}
      toolbarAction={
        showCreate && (
          <Button
            variant="contained"
            component={Link}
            to="/qre/new"
            sx={{ whiteSpace: "nowrap", py: 1 }}
          >
            New QRE
          </Button>
        )
      }
      aditionalToolbar={
        <QREFilterForm
          initialValues={{
            created_by: dataAUser?.[0]
              ? ({
                  value: dataAUser?.[0].id ?? "",
                  label: dataAUser?.[0].name ?? "",
                } as CustomerOptions)
              : null,
            status,
          }}
          onChange={handleFilterChange}
        />
      }
      params={{ search, page, perPage }}
      data={data?.items ?? []}
      totalCount={data?.total}
      isLoading={isLoading}
      isFetching={isFetching}
      onParamsChange={setParams}
      isFullscreen
    />
  );
};

export default QREList;
