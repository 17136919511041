import { zodResolver } from "@hookform/resolvers/zod";
import React, {
  FormHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import {
  DeepPartial,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormProps,
} from "react-hook-form";
import { ZodSchema } from "zod";

import isAppleDevice from "../../utils/isAppleDevice";

import { FormError } from "@/modules/common/types/sharedTypes";

type Props<T extends FieldValues> = Omit<
  FormHTMLAttributes<HTMLFormElement>,
  "onSubmit"
> & {
  children: React.ReactNode;
  onSubmit?: SubmitHandler<T>;
  defaultValues?: DeepPartial<T>;
  useFormProps?: UseFormProps;
  schema?: ZodSchema;
};

export default function Form<T extends FieldValues>({
  defaultValues,
  children,
  onSubmit,
  useFormProps = {},
  schema,
  ...rest
}: Props<T>) {
  const form = useForm({
    defaultValues,
    shouldFocusError: true,
    resolver: schema ? zodResolver(schema) : undefined,
    mode: "onChange",
    ...useFormProps,
  });

  const handleSubmit = useCallback(
    (data: T) =>
      (onSubmit?.(data) as Promise<unknown>)?.catch(
        ({ errors }: { errors?: FormError[] }) => {
          if (errors) {
            errors.forEach(({ message, type, field }, index) => {
              form.setError(
                Array.isArray(field) ? field.join(".") : field,
                {
                  type,
                  message,
                },
                { shouldFocus: index === 0 }
              );
            });
          }
        }
      ),
    [form, onSubmit]
  );

  const submitHandler = useMemo(
    () => form.handleSubmit(handleSubmit as SubmitHandler<FieldValues>),
    [form, handleSubmit]
  );

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (form.formState.isSubmitting) {
        e.preventDefault();
        return;
      }

      const cmdKey = isAppleDevice ? e.metaKey : e.ctrlKey;
      if (cmdKey && e.key === "s") {
        e.preventDefault();
        submitHandler();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [submitHandler, form.formState.isSubmitting]);

  return (
    <FormProvider {...form}>
      <form
        onSubmit={submitHandler}
        onKeyDown={(e) => {
          if (
            e.key === "Enter" &&
            (e.target as HTMLElement).tagName !== "TEXTAREA"
          )
            e.preventDefault();
        }}
        {...rest}
      >
        {children}
      </form>
    </FormProvider>
  );
}
