import { Box, Typography, AppBar } from "@mui/material";

import isAppleDevice from "../../utils/isAppleDevice";
import KeybindHint from "../KeybindHint";

import { FormSubmitButton } from "./FormSubmitButton";

const SubmitBar = () => {
  return (
    <>
      <AppBar
        position="fixed"
        color="default"
        component="div"
        elevation={0}
        sx={{
          bottom: 0,
          left: 0,
          top: "auto",
          right: 0,
          py: 1.5,
          px: 3,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          backgroundColor: (theme) => theme.palette.background.default,
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box display="flex" mr={1} alignItems="center">
          <KeybindHint kbd={isAppleDevice ? "⌘" : "Ctrl"} />
          <Typography variant="body2" color="textSecondary" mx="2px">
            {"+"}
          </Typography>
          <KeybindHint kbd="S" />
          <Typography variant="body2" color="textSecondary" ml={1}>
            {"to"}
          </Typography>
        </Box>
        <FormSubmitButton />
      </AppBar>
      <Box
        sx={{
          height: {
            xs: 0,
            sm: 32,
          },
        }}
      />
    </>
  );
};
export default SubmitBar;
