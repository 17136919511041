import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Button, Stack, Tab, Tabs } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import {
  Link,
  Outlet,
  To,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router";

import QRE from "../../QRE";

import { useAuth } from "@/modules/auth/AuthContext";
import BackButtonLink from "@/modules/common/components/BackButtonLink";
import TitledPage from "@/modules/common/components/layout/pages/TitledPage";
import QREStatusChip from "@/modules/common/components/QREStatusChip";

export const QREPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { checkAuthorization } = useAuth();
  const [showUpdate] = useMemo(
    () => checkAuthorization(["qre::update"]),
    [checkAuthorization]
  );
  const params = useParams();
  const id = params.id as string;
  const basePath = `/qre/${id}`;

  const isInfoTab = useMatch({
    path: "/qre/:id",
  });

  const isEdit = useMatch({
    path: "/qre/:id/edit",
  });

  const { data, isLoading } = useQuery({
    queryKey: ["qre", { id }],
    queryFn: () => QRE.getOne(id),
    gcTime: 0,
    throwOnError: true,
  });

  if (isEdit && !showUpdate) {
    navigate(`/qre/${id}`);
    return null;
  }

  return (
    <TitledPage
      icon={PersonIcon}
      title={data?.name || "Edit QRE"}
      actions={
        <Stack width="100%" direction="row" justifyContent="space-between">
          <QREStatusChip status={data?.status ?? "draft"} />
          <Stack direction="row" gap={4}>
            {(isInfoTab || isEdit) && showUpdate && isEdit && (
              <Button
                variant="contained"
                component={Link}
                to={`/qre/${id}`}
                sx={{ whiteSpace: "nowrap", gap: 1 }}
                replace
              >
                {<RemoveRedEyeIcon />}
                View
              </Button>
            )}
            {isInfoTab && showUpdate && !isEdit && (
              <Button
                variant="contained"
                component={Link}
                to={`/qre/${id}/edit`}
                sx={{ whiteSpace: "nowrap", gap: 1 }}
                replace
              >
                <EditIcon />
                Edit
              </Button>
            )}
            <BackButtonLink
              to={location.state?.fromListing ? (-1 as To) : "/qre"}
            />
          </Stack>
        </Stack>
      }
      isLoading={isLoading}
    >
      <Tabs
        value={location.pathname}
        scrollButtons="auto"
        variant="scrollable"
        sx={{
          my: 3,
        }}
      >
        <Tab
          value={isEdit ? `${basePath}/edit` : basePath}
          to={isEdit ? `${basePath}/edit` : basePath}
          label="Info"
          component={Link}
        />
        <Tab
          value={`${basePath}/changes`}
          to={`${basePath}/changes`}
          label="Changes History"
          component={Link}
        />
        <Tab
          value={`${basePath}/version`}
          to={`${basePath}/version`}
          label="Version History"
          component={Link}
        />
      </Tabs>
      <Outlet />
    </TitledPage>
  );
};
